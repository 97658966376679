import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../contexts/authContext/AuthContext";
import { catchError } from "../../../core/request/catchError";
import { hasAuthenticated } from "../../../services/authApi";
import { RefreshTokenApi } from "./ModalSessionType";
import { BoxButtonSession, CountModalSession, HeaderModalSession, MainModalSession, ModalSessionBody, WrapperSession } from "./ModalSessionStyle";
import Button from "../button/Button";
import { createPortal } from "react-dom";
import { useToasts } from "../../../contexts/toastContext/toastContext";
import { NAME_STORAGE_AUTH, NAME_STORAGE_REFRESH } from "../../../shared/constants/Constatnts";

const ModalSession = (): JSX.Element | null => {

    const { isAuthenticated, setIsAuthenticated, logout } = useAuth();
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [count, setCount] = useState<number>(15);
    const [loader, setLoader] = useState<boolean>(false);

    const fermerModalSession = useCallback(() => {
        logout();
        setIsAuthenticated(hasAuthenticated());
        setOpen(() => false);
            setCount(16);
    }, [logout, setIsAuthenticated]);

    useEffect(() => {
        count === 0 && fermerModalSession();
    }, [count, fermerModalSession]);

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (isAuthenticated.exp && isAuthenticated.connected) {
                const timeLeft = (isAuthenticated.exp * 1000) - 30000;
                setOpen(() => timeLeft <= Date.now());
            };
        };

        const interval = setInterval(checkTokenExpiration, 1000);

        return () => clearInterval(interval);
    }, [isAuthenticated.exp, isAuthenticated.connected]);

    useEffect(() => {
        if (isAuthenticated.connected && open) {
            const checkTokenExpiration = () => {
                setCount((curr: number) => curr - 1);
            };

            const interval = setInterval(checkTokenExpiration, 1000);

            return () => clearInterval(interval);
        };
    }, [isAuthenticated.connected, open]);

    const refresToken = () => {

        setLoader(() => true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/token/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh_token: isAuthenticated.refresh_token }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data: RefreshTokenApi) => {

                catchError(data);

                sessionStorage.setItem(NAME_STORAGE_AUTH, data.token);
                sessionStorage.setItem(NAME_STORAGE_REFRESH, data.refresh_token);

                setIsAuthenticated(hasAuthenticated());
                pushToast({ content: "Votre session a été prolongée", type: 'success', duration: 5 });
                setCount(16);
            })
            .catch(() => {
                setTimeout(() => {
                    logout();
                }, 1000);
            })
            .finally(() => {
                setLoader(() => false);
            });
    };

    if (open) {
        return createPortal(
            <WrapperSession>
                <ModalSessionBody>
                    <HeaderModalSession>
                        <h1>Votre session va expirer dans</h1>
                    </HeaderModalSession>

                    <MainModalSession>
                        <CountModalSession>{count}s</CountModalSession>

                        <BoxButtonSession>
                            <Button
                                label="Quitter l'application"
                                width={220}
                                type="button"
                                loader={false}
                                color="crimson"
                                colorlabel="#FFFFFF"
                                disabled={loader}
                                onClick={fermerModalSession}
                            />

                            <Button
                                label="Prolonger ma session"
                                width={220}
                                type="button"
                                loader={loader}
                                color="#0288d1"
                                colorlabel="#FFFFFF"
                                disabled={loader}
                                onClick={refresToken}
                            />
                        </BoxButtonSession>
                    </MainModalSession>
                </ModalSessionBody>
            </WrapperSession>, document.body
        );
    };

    return null;
};
export default ModalSession;