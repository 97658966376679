import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import Accordion from "../../../common/accordion/Accordion"
import InputCustom from "../../../common/field/inputCustom/InputCustom";
import Button from "../../../common/button/Button";
import { DonneDuCentre, ModifieDonneDuCentreProps, ValidationDataCentreSchema } from "./ModifieDonneDuCentreType";
import { catchError } from "../../../../core/request/catchError";
import { AuthContext, AuthTest } from '../../../../contexts/authContext/AuthContext';
import { BoxBtnAnnulModifiModifieDonne, BoxBtnModifieDonne, BoxFieldModifieDonne } from "./ModifieDonneDuCentreStyle";
import { useToasts } from "../../../../contexts/toastContext/toastContext";

const ModifieDonneDuCentre = ({ data }: ModifieDonneDuCentreProps): JSX.Element => {

    const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;
    const { pushToast } = useToasts();

    const [dataCentre, setDataCentre] = useState<DonneDuCentre>({
        capital: '',
        siret: '',
        numTva: '',
        telephone: '',
        tauxHoraireHt: '',
        urlVosFactures: '',
        apiKeyVosFactures: '',
        nomSociete: '',
    });

    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [btnIsDisabled, setBtnIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        data && setDataCentre((curr: DonneDuCentre) => {
            return { ...curr, ...data };
        });
    }, [data]);

    const handleChangeDataCentre = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setBtnIsDisabled(() => false);

        setDataCentre((curr: DonneDuCentre) => {
            return { ...curr, [name]: value }
        });
    };

    const annule = () => {
        data && setDataCentre((curr: DonneDuCentre) => {
            return { ...curr, ...data };
        });

        setBtnIsDisabled(() => true);
    };

    const submitData = (e: FormEvent) => {
        e.preventDefault();

        if (!ValidationDataCentreSchema.safeParse(dataCentre).success) {
            pushToast({ content: "Une donnée n'est pas conforme ou est manquante", type: 'alert', duration: 5 });
            return;
        };

        setBtnIsDisabled(() => true);
        setSubmitLoader(() => true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/update/account/${id}`,
            {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(dataCentre)
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: `Les données ont été mises à jour`, type: 'success', duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setSubmitLoader(() => false);
            });
    };

    return (
        <Accordion title="Compte utilisateur" forceOpen={true}>
            <form onSubmit={submitData}>
                <BoxFieldModifieDonne>
                    <InputCustom
                        width={48.5}
                        label="Nom de la société"
                        id="nomSociete"
                        type="text"
                        value={dataCentre.nomSociete}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={""}
                    />

                    <InputCustom
                        width={48.5}
                        label="Capital de la société"
                        id="capital"
                        type="text"
                        value={dataCentre.capital}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={""}
                    />
                </BoxFieldModifieDonne>

                <BoxFieldModifieDonne>
                    <InputCustom
                        width={48.5}
                        label="Numéro de siret de la société"
                        id="siret"
                        type="text"
                        value={dataCentre.siret}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={""}
                    />

                    <InputCustom
                        width={48.5}
                        label="Numéro de TVA de la société"
                        id="numTva"
                        type="text"
                        value={dataCentre.numTva}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={""}
                    />
                </BoxFieldModifieDonne>

                <BoxFieldModifieDonne>
                    <InputCustom
                        width={48.5}
                        label="Téléphone de la société"
                        id="telephone"
                        type="phone"
                        value={dataCentre.telephone}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={""}
                    />

                    <InputCustom
                        width={48.5}
                        label="Taux horaire HT"
                        id="tauxHoraireHt"
                        type="text"
                        value={dataCentre.tauxHoraireHt}
                        onChange={handleChangeDataCentre}
                        pattern='[0-9]+([.][0-9]{0,2})?'
                        require={false}
                        error={""}
                    />
                </BoxFieldModifieDonne>

                <BoxFieldModifieDonne>
                    <InputCustom
                        width={48.5}
                        label="Url du compte VosFactures.fr"
                        id="urlVosFactures"
                        type="text"
                        value={dataCentre.urlVosFactures}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={""}
                    />

                    <InputCustom
                        width={48.5}
                        label="API-key du compte VosFactures.fr"
                        id="apiKeyVosFactures"
                        type="text"
                        value={dataCentre.apiKeyVosFactures}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={""}
                    />
                </BoxFieldModifieDonne>

                <BoxBtnModifieDonne>
                    <BoxBtnAnnulModifiModifieDonne>
                        <Button
                            label="Annuler"
                            type="button"
                            width={90}
                            color="crimson"
                            colorlabel="#EEEEEE"
                            onClick={annule}
                            loader={false}
                            disabled={btnIsDisabled}
                        />

                        <Button
                            label="Modifier"
                            type="submit"
                            width={100}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            loader={submitLoader}
                            disabled={btnIsDisabled}
                        />
                    </BoxBtnAnnulModifiModifieDonne>
                </BoxBtnModifieDonne>
            </form>
        </Accordion>
    );
};

export default ModifieDonneDuCentre;