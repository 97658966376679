import { z } from "zod";
import { DataCentre } from "../../../../shared/class/Centre";

const messageStr = { invalid_type_error: 'Le format est incorrect' };
const regexTauxHoraire = new RegExp('[0-9]+([.][0-9]{0,2})?');
const emailRegex = new RegExp('[^@]+@[^@]+.[^@]+$');

export const DataCentreSchema = z.object({ 
    id: z.number().optional(),
    nomSociete: z.string(messageStr).min(1, { message: 'La civilité est obligatoire' }),
    nomDirigeant: z.string(messageStr).min(1, { message: 'Le nom du dirigeant du centre est obligatoire' }),
    prenomDirigeant: z.string(messageStr).min(1, { message: 'Le prénom du dirigeant du centre est obligatoire' }),
    email: z.string(messageStr).regex(emailRegex, { message: 'Le format de l\'adresse e-mail est invalide' }),
    siret: z.string(messageStr).min(1, { message: 'Le siret est obligatoire' }),
    numTva: z.string(messageStr).min(1, { message: 'Le numéro de TVA est obligatoire' }),
    capital: z.string(messageStr).min(1, { message: 'Le capital est obligatoire' }),
    telephone: z.string(messageStr).min(10, { message: 'Le numéro de téléphone est obligatoire' }).max(10, { message: 'Le numéro de téléphone est obligatoire' }),
    tauxHoraireHt: z.string(messageStr).regex(regexTauxHoraire, { message: 'Le taux horaire hors taxe est obligatoire' }),
    urlVosFactures: z.string(messageStr).min(1, { message: 'L\'url de votre compte VosFactures.fr est obligatoire' }),
    apiKeyVosFactures: z.string(messageStr).min(1, { message: 'L\'apiKey de votre compte est obligatoire' }),
    dateEditionKbis: z.string(messageStr).min(1, { message: 'La date édition du Kbis est obligatoire' }),
    dateExpireKbis: z.string(messageStr).min(1, { message: 'La date expire du Kbis est obligatoire' }),
    createdAt: z.string(messageStr).min(1, { message: 'La date de création du compte est obligatoire' }),
    updateAt: z.string(messageStr).min(1, { message: 'La date de derniere modification du compte est obligatoire' }),
});

export const ValidationDataCentreSchema = z.object({
    capital: z.string(messageStr).min(1, { message: 'Le capital est obligatoire' }),
    siret: z.string(messageStr).min(1, { message: 'Le siret est obligatoire' }),
    numTva: z.string(messageStr).min(1, { message: 'Le numéro de TVA est obligatoire' }),
    telephone: z.string(messageStr).min(10, { message: 'Le numéro de téléphone est obligatoire' }).max(10, { message: 'Le numéro de téléphone est obligatoire' }),
    tauxHoraireHt: z.string(messageStr).regex(regexTauxHoraire, { message: 'Le taux horaire hors taxe est obligatoire' }),
    urlVosFactures: z.string(messageStr).min(1, { message: 'L\'url de votre compte VosFactures.fr est obligatoire' }),
    apiKeyVosFactures: z.string(messageStr).min(1, { message: 'L\'apiKey de votre compte est obligatoire' }),
    nomSociete: z.string(messageStr).min(1, { message: 'La civilité est obligatoire' }),
});

export type DonneDuCentre = z.infer<typeof ValidationDataCentreSchema>;

export type ModifieDonneDuCentreProps = {
    data: DataCentre
};

