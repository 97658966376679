import styled from "styled-components";

export const MainCustom = styled.main`
    min-height: 100vh;
    background-color: ${props => props.theme.color.grayLight};
    box-shadow: 0px 0px 10px #757575;
    @media screen and (min-width: 1024px) {
        width: 60vw;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 100vw;
    }
    @media screen and (max-width: 767px) {
        width: 100vw; 
    }
`;

export const TilePage = styled.h2`
    width: 100%;
    text-align: center;
    color: ${props => props.theme.color.gray};
    margin: 25px 0;
    @media screen and (max-width: 767px) {
        font-size: 18px;
        margin: 15px 0;
    }
`;