import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DocumentsErreur } from '../../../../shared/class/DocumentsErreur';
import LabelCustom from '../labelCustom/LabelCustom';
import { InputFileAccountProps } from './InputFileAccountType';
import { BoxGlobalInputFileAccount, BoxIconLabelAccount, BoxInputFileAccount, CameraAccount, ErrorInputFileAccount, LabelInputFileAccount , BoxInputFileIconClose,  IconRestFile, InputFileAccount2} from './InputFileAccountStyle';

const InputFileAccount = ({ label, file, setFile, setError, error, nameError, require = false }: InputFileAccountProps): JSX.Element => {

    const [colorBorder, setcolorBorder] = useState<string>('#e0e0e0');
    const [colorLabel, setcolorLabel] = useState<string>('#616161');

    const [nameDoc, setNameDoc] = useState<string | null>(null); 

    const resetFile = useCallback(() => {
        setFile();
        setNameDoc(null);
        setcolorBorder('#e0e0e0');
        setcolorLabel('#616161'); 
    }, [setFile]);

    useEffect(() => {
        !file && resetFile();
    }, [file, resetFile]);

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        setError('');
        setcolorBorder('#e0e0e0');
        setcolorLabel('#616161');

        if (e.target.files && e.target.files[0]) {

            if (e.target.files[0].size >= 67000000) {
                setError((error: DocumentsErreur) => {
                    const err = { ...error, [nameError]: 'Le document est limité a 67ko' };
                    return err;
                });

                return;
            };

            setFile(e.target.files[0]);
            setNameDoc(e.target.files[0].name);
            e.target.files[0] ? setcolorBorder('green') : setcolorBorder('#e0e0e0');
            e.target.files[0] ? setcolorLabel('green') : setcolorLabel('#616161');
        };
    };

    return (
        <BoxGlobalInputFileAccount $nameDocument={nameDoc}>

            {label !== '' && <LabelCustom label={label} require={require} />}

            <BoxInputFileIconClose>
                <BoxInputFileAccount $color={colorBorder}>
                    <BoxIconLabelAccount>
                        <CameraAccount $colorLabel={colorLabel} />
                        <LabelInputFileAccount
                            $colorLabel={colorLabel}>
                            {nameDoc ? nameDoc : 'Ajouter un fichier...'} 
                        </LabelInputFileAccount>
                    </BoxIconLabelAccount>

                    <InputFileAccount2
                        onChange={onChangeFile}
                        type="file"
                        capture="environment"
                        accept="image/*"
                    />
                </BoxInputFileAccount>

                {nameDoc && <IconRestFile onClick={resetFile} />}

            </BoxInputFileIconClose>

            <ErrorInputFileAccount>{error}</ErrorInputFileAccount>

        </BoxGlobalInputFileAccount>
    );
};

export default InputFileAccount;