import { useContext } from "react";
import { TilePage } from "../../components/common/basePage/BasePageStyle";
import { LinkStyleBtn, LinkStyleBtnDeclaration, Nav } from "./AccueilStyle";
import Header from "../../components/common/header/header/Header";
import BasePage from "../../components/common/basePage/BasePage";
import { SinistreContext } from "../../contexts/sinistreContext/SinistreContext";
import { useCredit } from "../../contexts/creditcContext/CreditContext";
import { STATUS_CREDIT_BLOQUE } from "../../shared/constants/Constatnts";
import { useNavigate } from "react-router-dom";
import { useToasts } from "../../contexts/toastContext/toastContext";

const Accueil = (): JSX.Element => {
    const { pushToast } = useToasts();
    const { resetSinistre } = useContext(SinistreContext);
    const { creditStatus } = useCredit();

    const navigate = useNavigate();

    const redirectToDeclaration = () => {
        resetSinistre();

        if (creditStatus === STATUS_CREDIT_BLOQUE) {
            pushToast({ content: "Vos crédits sont épuisés, vous ne pouvez plus faire de déclaration", type: 'alert', duration: 5 });
            return;
        };

        navigate(`/declaration/assure`);
    };

    const resetSinistreContext = () => {
        resetSinistre();
    };

    return (
        <BasePage>
            <Header />

            <TilePage>Accueil</TilePage>

            <Nav>
                <LinkStyleBtnDeclaration onClick={redirectToDeclaration}>Faire une déclaration</LinkStyleBtnDeclaration>
                <LinkStyleBtn onClick={resetSinistreContext} to={`/dossier/client`}>Dossier Client</LinkStyleBtn>
            </Nav>
        </BasePage>
    );
};

export default Accueil;