import { z } from 'zod';

const messageStr = { invalid_type_error: 'Le format est incorrect' };
const messageRegex = { message: 'Le format est incorrect' };

const regexCodePostal = new RegExp('.*[0-9].*');
const regexTelephone = new RegExp('.*[0-9].*');

export const AssureSchema = z.object({
  isPro: z.boolean(),
  nomSociete: z.string(messageStr).optional(),
  numSiret: z.string(messageStr).optional(),
  civilite: z.string(messageStr).min(1, { message: 'La civilité est obligatoire' }).optional(),
  nom: z.string(messageStr).min(1, { message: 'Le nom est obligatoire' }),
  prenom: z.string(messageStr).min(1, { message: 'Le prénom est obligatoire' }),
  adresse: z.string(messageStr).min(1, { message: 'L\'adresse est obligatoire' }),
  codePostal: z.string(messageStr).regex(regexCodePostal, messageRegex).min(1, { message: 'Le code postal est obligatoire' }),
  ville: z.string(messageStr).min(1, { message: 'La ville est obligatoire' }),
  telephone: z.string(messageStr).regex(regexTelephone, messageRegex).min(10, { message: 'Le numéro de téléphone est obligatoire' }).max(10, { message: 'Le numéro de téléphone est obligatoire' }),
  email: z.union([z.string(messageStr).email({ message: 'L\'adresse e-mail est invalide' }), z.literal('')]).nullable()
});

export const AssureIsProTrueSchema = z.object({
  isPro: z.boolean(),
  nomSociete: z.string(messageStr).min(1, { message: 'Le nom de société est obligatoire' }),
  numSiret: z.string(messageStr).min(14, { message: 'Le numéro SIRET est obligatoire' }).max(14, { message: 'Le numéro SIRET est composer de 14 caractere maximum' }).nullable(),
  civilite: z.union([z.string(messageStr).min(1, { message: 'La civilité est obligatoire' }).optional(), z.literal('')]).nullable(),
  nom: z.union([z.string(messageStr).min(1, { message: 'Le nom est obligatoire' }), z.literal('')]).nullable(),
  prenom: z.union([z.string(messageStr).min(1, { message: 'Le prénom est obligatoire' }), z.literal('')]).nullable(),
  adresse: z.string(messageStr).min(1, { message: 'L\'adresse est obligatoire' }),
  codePostal: z.string(messageStr).regex(regexCodePostal, messageRegex).min(1, { message: 'Le code postal est obligatoire' }),
  ville: z.string(messageStr).min(1, { message: 'la ville est obligatoire' }),
  telephone: z.string(messageStr).regex(regexTelephone, messageRegex).min(10, { message: 'Le numéro de téléphone est obligatoire' }).max(10, { message: 'Le numéro de téléphone est obligatoire' }),
  email: z.string(messageStr).email({ message: 'L\'adresse e-mail est invalide' }).min(1, { message: 'L\'adresse e-mail est obligatoire' })
});


//numSiret: z.union([z.string(messageStr).min(14, { message: 'Le numéro SIRET est obligatoire' }).max(14, { message: 'Le numéro SIRET est composer de 14 caractere maximum' }), z.literal('')]).nullable(),


export const AssureUpdateSchema = z.object({
  isPro: z.boolean(),
  nomSociete: z.string().nullable(),
  numSiret: z.string().nullable(),
  civilite: z.string(),
  nom: z.string(),
  prenom: z.string(),
  adresse: z.string(),
  codePostal: z.string(),
  ville: z.string(),
  telephone: z.string(),
  email: z.string().optional()
});

export const AssureUpdateRetourSchema = z.object({
  status: z.number(),
  detail: z.string(),
  idSinistre: z.number()
});

export type AssureType = z.infer<typeof AssureSchema>;
export type AssureUpdateType = z.infer<typeof AssureUpdateSchema>;
export type AssureUpdateRetourType = z.infer<typeof AssureUpdateRetourSchema>;
