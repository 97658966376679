import styled from "styled-components";
import { theme } from "../../theme/theme";

export const BoxFieldFormDocumentAnnexe = styled.div`
  margin-top: 25px; 
  width: 100%;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    justify-content: center;
  }
`; 

export const BoxFieldDocumentAnnexe = styled.div`
    width: 50%;
    @media screen and (min-width: 1024px) {
        width: 50%; 
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const BoxBtnFormDocumentAnnexe = styled.div`
  margin-top: 15px; 
  width: 100%;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
`; 

export const BoxBtnFinaliseFormDocumentAnnexe = styled.div`
  margin-top: 100px; 
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  @media screen and (max-width: 767px) {
    margin-top: 50px; 
  }

`; 

export const FormDocumentAnnexeParag = styled.p`
  margin: 25px 0; 
  font-size: ${theme.font.medium};
  color:${theme.color.letterGray};
`;