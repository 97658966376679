import styled from "styled-components";
import { Camera, XCircle } from 'react-bootstrap-icons';
import { theme } from "../../../../theme/theme";

export type InputFileCustomStyleProps = {
    $color?: string,
    $colorLabel?: string,
    $nameDocument? : string | null
};

export const BoxGlobalInputFileCustom = styled.div<InputFileCustomStyleProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (min-width: 1024px) {
        width: ${props => props.$nameDocument ? `93%` : `100%`}; 
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: ${props => props.$nameDocument ? `94.5%` : `100%`}; 
    }
    @media screen and (max-width: 767px) {
        width: 86%;
        width: ${props => props.$nameDocument ? `89%` : `100%`}; 
    }
`;

export const BoxInputFileCustom = styled.div<InputFileCustomStyleProps>`
    position: relative; 
    width: 100%;
    height: 48px;
    padding: 5px 10px; 
    border: dashed 2px ${props => props.$color};
    background-color: white; 
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        height: 41px;
        display: flex;
    }
`;

export const BoxInputFileIconClose = styled.div`
    position: relative; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    &:hover{
        cursor: pointer;
    }
`;

export const BoxIconLabelCustom = styled.div`
    width: 100%;
    background-color: transparent;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start; 
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
`;

export const CameraCustom2 = styled(Camera)<InputFileCustomStyleProps>`
    color: ${props => props.$colorLabel};
    margin-right: 20px;
    font-size: 35px;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
         font-size: 20px;
    }
`;

export const IconRestFile = styled(XCircle)`
    font-size: 30px; 
    position: absolute;
    right: -40px; 
    color: ${theme.color.alert};
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        font-size: 25px;
    }
`; 

export const LabelInputFileCustom = styled.label<InputFileCustomStyleProps>`
    color: #616161;
    font-size: ${props => props.theme.font.medium};
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.small};
    }
`;

export const InputFileCustom2 = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
`;

export const ErrorInputFileCustom = styled.p`
    margin-top: 5px;
    color: ${theme.color.alert}; 
    font-size: 16px;
    height: 16px;
`;





